import React, { ReactElement } from 'react'
import materialShopLogin from '@services/api/materialShopLogin'
import {
  $materialShopPaths,
  $materialShopSessionId,
} from '@services/stores/materialShop'
import { Formik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { navigate } from 'gatsby'
import Container from '@objects/container'
import Headline from '@objects/headline'
import { TextField } from '@objects/formfields'
import Button from '@objects/button'
import { makeStyles } from '@material-ui/core'
import { useStore } from '@nanostores/react'

const useStyles = makeStyles((theme) => ({
  form: {
    paddingTop: theme.spacing(12),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(20),
    },
  },
  headline: {
    fontWeight: 700,
  },
  button: {
    marginTop: theme.spacing(4),
  },
}))

export default function MaterialShopLoginForm(): ReactElement {
  const intl = useIntl()
  const classes = useStyles()
  const materialShopPaths = useStore($materialShopPaths)

  return (
    <Formik
      initialValues={{
        token: '',
      }}
      onSubmit={(values, formikHelpers) => {
        materialShopLogin(values.token)
          .then((response: Response) => {
            if (response.status !== 202) {
              formikHelpers.setSubmitting(false)
              formikHelpers.setErrors({
                token: intl.formatMessage({
                  id: 'materialshop.form.error.password',
                }),
              })
              return
            }

            $materialShopSessionId.set(values.token)
            navigate(materialShopPaths.shop)
          })
          .catch(() => {
            formikHelpers.setSubmitting(false)
            formikHelpers.setErrors({
              token: intl.formatMessage({
                id: 'materialshop.form.error.password',
              }),
            })
          })
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Container className={classes.form} width={'sm'}>
          <Headline level={5} className={classes.headline}>
            <FormattedMessage id={'materialshop.login.headline'} />
          </Headline>
          <TextField
            variant="outlined"
            id="token"
            customVariant="form"
            name="token"
            type="password"
            label=""
            placeholder={intl.formatMessage({
              id: 'materialshop.form.password',
            })}
          />
          <Button
            className={classes.button}
            onClick={() => handleSubmit()}
            disabled={isSubmitting}
            icon="ChevronRight"
            iconPosition="right"
            formSubmit
          >
            <FormattedMessage id={'materialshop.login.button'} />
          </Button>
        </Container>
      )}
    </Formik>
  )
}
